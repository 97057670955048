<template>
  <div class="widget-show">
    <div class="main-content">
      <div class="top-actions">
        <BreadcrumbNavConnected class="breadcrumb"/>
        <button v-if="widget.id" @click="deleteWidget" class="delete-widget">
          <svg>
            <use xlink:href="#icon-trash"></use>
          </svg>
          <span>{{ $t("Delete Widget") }}</span>
        </button>
      </div>

      <ContentBlock :required="true">
        <div slot="instructions">
          <h5>{{ $t('Widget Details') }}</h5>
        </div>
        <div class="details">
          <div class="grid-one">
            <UIInput
              :title="$t('Internal Name')"
              :instructions="$t('The internal name for this widget')"
              v-model="widget.name"
              required
            />
            <UIInput
              :title="$t('Button Text')"
              :instructions="$t('The text that will be displayed on the reservation button')"
              v-model="widget.buttonText"
              required
            />
            <UISwitch
              :title="$t('Mini Mode')"
              :instructions="$t('If mini mode is selected there will be no text displayed for the widget')"
              v-model="widget.mini"
            />
            <UIFormItem :root="true" :title="$t('Button background color')">
              <chrome v-model="colorButtonBackground" :disableAlpha="true"/>
            </UIFormItem>
            <UIFormItem :root="true" :title="$t('Button Text Color')">
              <chrome v-model="colorButtonText" :disableAlpha="true"/>
            </UIFormItem>
            <UIFormItem
              :root="true"
              :title="$t('Example Button')"
              :instructions="$t('This is a preview of what your customers would see. The button is against white in SpringboardVR reservation application.')"
            >
              <button class="example-button" :style="buttonStyle">{{ widget.buttonText }}</button>
            </UIFormItem>
          </div>
          <br />
          <div class="grid-three">
            <UIInput
              :title="$t('Redirect URL')"
              :instructions="$t('The URL used to redirect customers back to your site')"
              v-model="widget.redirectUrl"
            />
          </div>
        </div>
      </ContentBlock>

      <ContentBlock>
        <div slot="instructions">
          <h5>{{ $t('Styling') }}</h5>
          <p>{{ $t('Customize booking panel') }}</p>
        </div>
        <div class="image-upload mb-md">
          <div class="onecolumn">
            <template v-if="widget">
              <UIFormItem :title="$t('Upload Your own logo')" />
              <UIRadioThumbnail
                class="image dib w-100 h-100 mb-sm"
                v-if="widget.logoUrl || defaultLogoImage"
                value="logo"
                name="logo-image"
                :style="{ backgroundImage: `url(${widget.logoUrl || defaultLogoImage})` }"
              />
            </template>
            <div>
              <UIFileUpload @change="logoChange" rules="image"/>
              <small class="f-instructions">{{ $t('Recommended size') }}</small>
            </div>
          </div>
          <div class="select-image">
            <UIFormItem :title="$t('Select a logo')" />
            <div class="imagegrid grid-three">
              <UIRadioThumbnail
                class="image dib w-100 h-100"
                v-for="image in defaultLogoImages"
                v-model="widget.logoUrl"
                :value="image"
                :key="image"
                @input="selectDefaultLogoImage(image)"
                name="image"
                :style="{ backgroundImage: `url(${image})` }"
              />
            </div>
          </div>
        </div>
        <div class="image-upload">
          <div class="onecolumn">
            <template v-if="widget">
              <UIFormItem :title="$t('Upload Your Background image')" />
              <UIRadioThumbnail
                class="bg-image dib w-100 h-100 mb-sm"
                v-if="widget.backgroundImageUrl"
                name="bg-image"
                value="backgroundImage"
                :style="{ backgroundImage: `url(${widget.backgroundImageUrl})` }"
              />
            </template>
            <div>
              <UIFileUpload @change="backgroundChange" rules="image"/>
              <small class="f-instructions">{{ $t('Recommended size') }}</small>
            </div>
          </div>
          <div class="select-image">
            <UIFormItem :title="$t('Select a background image')" />
            <div class="imagegrid grid-four">
              <UIRadioThumbnail
                class="bg-image dib w-100 h-100"
                v-for="image in defaultBackgroundImages"
                v-model="widget.backgroundImageUrl"
                :value="image"
                :key="image"
                name="image"
                @input="selectImage(image, 'backgroundImage')"
                :style="{ backgroundImage: `url(${image})` }"
              />
              <UIRadioThumbnail
                class="bg-image dib w-100 h-100"
                v-model="widget.backgroundImageUrl"
                key="no-image"
                name="image"
                :value="null"
                @input="clearBackgroundImageInput()"
              >No Image</UIRadioThumbnail>
            </div>
          </div>
        </div>
      </ContentBlock>
      <ContentBlock :required="true">
        <div slot="instructions">
          <h5>{{ $t('Analytics') }}</h5>
          <p>{{ $t('We offer basic analytics, using Google Tag Manager, or a more advanced setup using "pixels", that gives you full control.') }}</p>
        </div>
        <div class="details">
          <div class="grid-three">
            <UISwitch
              :title="$t('Use advanced analytics')"
              :value="advancedAnalytics"
              @change="toggleAdvancedAnalytics($event)"
            />
          </div>
          <div class="grid-three f5" v-if="!advancedAnalytics">{{ $t('The Google Tag Manager will be called once for the first three steps of the reservation and once for the confirmation page.') }}</div>
          <div class="grid-three" v-if="!advancedAnalytics">
            <UIInput
              :title="$t('GTM code')"
              :instructions="$t('The Google Tag Manager code, typically GMT-xxxxxxx')"
              v-model="widget.uaCode"
            />
          </div>
          <div class="grid-three f5" v-if="advancedAnalytics">{{ $t('Every step of the booking process contains a "pixel" for analytics, which loads a page on your website. The query string parameters used to call the widget on your website (e.g. landing pages), will be added to the pixel URL, so you know where the user came from. This way you can differentiate between multiple marketing campaigns.') }}</div>
          <div class="grid-three" v-if="advancedAnalytics">
            <UIInput
              :title="$t('Pixel URL - Step 1')"
              :instructions="$t('A URL on your system where you have set up Analytics')"
              v-model="widget.analyticsPixelUrlStep1"
            />
          </div>
          <div class="grid-three" v-if="advancedAnalytics">
            <UIInput
              :title="$t('Pixel URL - Step 2')"
              :instructions="$t('A URL on your system where you have set up Analytics')"
              v-model="widget.analyticsPixelUrlStep2"
            />
          </div>
          <div class="grid-three" v-if="advancedAnalytics">
            <UIInput
              :title="$t('Pixel URL - Step 3')"
              :instructions="$t('A URL on your system where you have set up Analytics')"
              v-model="widget.analyticsPixelUrlStep3"
            />
          </div>
          <div class="grid-three" v-if="advancedAnalytics">
            <UIInput
              :title="$t('Pixel URL - Booking confirmation')"
              :instructions="$t('A URL on your system where you have set up Analytics')"
              v-model="widget.analyticsPixelUrlConfirm"
            />
          </div>
        </div>
      </ContentBlock>
    </div>
    <bottom-bar
      @save="storeWidget"
      :saving="saving"
      :valid="valid"
      :backRouterLink="{ name: 'widget-index' }"
    />
  </div>
</template>

<script>
import Component, { mixins } from 'vue-class-component';
import ContentBlock from '@/core/shared/components/ContentBlock';
import FocusModeMixin from '@/core/shared/misc/FocusModeMixin';
import BottomBar from '@/core/shared/components/BottomBar';
import { isEqual, isEmpty } from 'lodash-es';
import { Chrome } from 'vue-color';
import BreadcrumbNavConnected from '@/core/shared/components/_connected/BreadcrumbNavConnected';
import UIButton from '@/core/shared/components/ui/UIButton';
import UISwitch from '@/core/shared/components/ui/UISwitch';
import UIInput from '@/core/shared/components/ui/UIInput';
import UIFormItem from '@/core/shared/components/ui/UIFormItem';
import UIFileUpload from '@/core/shared/components/ui/UIFileUpload';
import UIRadioThumbnail from '@/core/shared/components/ui/UIRadioThumbnail';
import FileHelper from '@/core/shared/helpers/FileHelper';

@Component({
  props: {
    widgetId: {
      type: String,
      required: true,
    },
    locationId: {
      type: String,
      required: true,
    },
  },
  components: {
    ContentBlock,
    BottomBar,
    Chrome,
    UIButton,
    UISwitch,
    UIInput,
    UIFormItem,
    BreadcrumbNavConnected,
    UIFileUpload,
    UIRadioThumbnail,
  },
})
export default class WidgetShow extends mixins(FocusModeMixin) {
  defaultLogoImages = [
    'https://storage.googleapis.com/api-storage.springboardvr.com/assets/widgets/powered_by_sbvr.png',
  ];

  defaultBackgroundImages = [
    'https://storage.googleapis.com/api-storage.springboardvr.com/assets/widgets/bg_AFT.jpg',
    'https://storage.googleapis.com/api-storage.springboardvr.com/assets/widgets/bg_AZS.jpg',
    'https://storage.googleapis.com/api-storage.springboardvr.com/assets/widgets/bg_AtF.jpg',
  ];

  defaultWidget = {
    name: '',
    buttonText: '',
    mini: false,
    colorButtonBackground: '000000',
    colorButtonText: 'ffffff',
    location: {
      id: this.locationId,
    },
    uaCode: null,
    analyticsPixelUrlStep1: null,
    analyticsPixelUrlStep2: null,
    analyticsPixelUrlStep3: null,
    analyticsPixelUrlConfirm: null,
    logoUrl: this.defaultLogoImages[0],
    backgroundImageUrl: null,
  };

  defaultLogoImage = null;

  widgetChanges = {};
  saving = false;

  advancedAnalyticsInitialized = false;
  advancedAnalytics = false;

  toggleAdvancedAnalytics (e) {
    if (this.advancedAnalytics) {
      this.advancedAnalytics = false;
    } else {
      this.advancedAnalytics = true;
    }
  }

  initializeAdvancedAnalytics (widget) {
    if (widget && !this.advancedAnalyticsInitialized) {
      this.advancedAnalytics = !widget.uaCode && widget.analyticsPixelUrlStep1;
      this.advancedAnalyticsInitialized = true;
    }
    return this.advancedAnalytics;
  }

  async logoChange ([file]) {
    await this.imageUpload(file, 'logo');
  }

  async backgroundChange ([file]) {
    await this.imageUpload(file, 'backgroundImage');
  }

  async imageUpload (file, field) {
    const imageUrl = await FileHelper.encodeImage(file);
    const blob = {
      ...this.widget._blob || {},
      [field]: {
        type: 'file',
        file,
      },
    };

    this.$set(this.widget, '_blob', blob);
    this.$set(this.widget, `${field}Url`, imageUrl);
  }

  selectImage (imageUrl, field) {
    const blob = {
      ...this.widget._blob || {},
      [field]: {
        type: 'url',
        file: imageUrl,
      },
    };

    this.widget[`${field}Url`] = imageUrl;
    this.$set(this.widget, '_blob', blob);
    this.$set(this.widget, `${field}Url`, imageUrl);
  }

  selectDefaultLogoImage (imageUrl) {
    this.defaultLogoImage = imageUrl;
    this.widget.logoUrl = '';
    this.selectImage('', 'logo');
  }

  clearBackgroundImageInput () {
    this.widget.backgroundImageUrl = null;
    this.selectImage('', 'backgroundImage');
  }

  get widget () {
    return new Proxy({ ...this.widgetInStore, ...this.widgetChanges }, {
      set: (object, property, value) => {
        if (isEqual(this.widgetInStore[property], value)) {
          this.$delete(this.widgetChanges, property, value);
        } else {
          this.$set(this.widgetChanges, property, value);
        }
        return true;
      },
    });
  }

  get widgetInStore () {
    const widget = (this.widgetId === 'new') ? this.defaultWidget : this.$store.getters['entities/Widget'](this.widgetId);
    this.initializeAdvancedAnalytics(widget);
    return widget;
  }

  get valid () {
    return !!this.widget.name?.length > 0 && !!this.widget.buttonText.length;
  }

  get colorButtonBackground () {
    return `#${this.widget.colorButtonBackground}`;
  }

  set colorButtonBackground (colorObj) {
    this.widget.colorButtonBackground = colorObj.hex.substr(1);
  }

  get colorButtonText () {
    return `#${this.widget.colorButtonText}`;
  }

  set colorButtonText (colorObj) {
    this.widget.colorButtonText = colorObj.hex.substr(1);
  }

  get buttonStyle () {
    return {
      color: '#' + this.widget.colorButtonText,
      background: '#' + this.widget.colorButtonBackground,
      borderColor: '#' + this.widget.colorButtonBackground,
    };
  }

  async created () {
    if (isEmpty(this.widgetInStore)) await this.$store.dispatch('getLocationWidgets', this.locationId);
    this.show = true;

    let title;
    if (this.widgetId === 'new') {
      this.widgetChanges = this.defaultWidget;
      title = this.$t('New Widget');
    } else {
      this.widgetChanges.id = this.widget.id;
      title = this.$t('Edit Widget');
    }

    this.$store.commit('setTitle', title);

    this.setBreadcrumb({
      indexTitle: this.$tc('Widget', 2),
      editTitle: title,
      indexRouteName: 'widget-index',
    });
  }

  async storeWidget () {
    try {
      if (this.advancedAnalytics) {
        this.widget.uaCode = null;
      } else {
        this.widget.analyticsPixelUrlStep1 = null;
        this.widget.analyticsPixelUrlStep2 = null;
        this.widget.analyticsPixelUrlStep3 = null;
        this.widget.analyticsPixelUrlConfirm = null;
      }

      await this.$store.dispatch('storeWidget', this.widget);
      this.saving = false;
      this.$store.commit('setFlash', {
        message: `${this.$t('Widget saved')}`,
        type: 'success',
      });
      this.$router.push({ name: 'widget-index' });
    } finally {
      this.saving = false;
    }
  }

  deleteWidget () {
    this.$store.commit('setConfirm', {
      show: true,
      message: this.$t('Are you sure you would like to delete this widget?'),
      icon: 'icon-trash',
      buttons: [
        {
          name: this.$t('No'),
          type: 'cancel',
        },
        {
          name: this.$t('Yes'),
          action: async () => {
            await this.$store.dispatch('deleteWidget', this.widget);
            this.$router.push({ name: 'widget-index' });
          },
        },
      ],
    });
  }
}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";

.widget-show {
  & >>> .instructions {
    @media (--desktop) {
      flex: 0 0 20rem;
      margin-right: var(--spacingSm);
    }
  }
  & .helper {
    display: block;
    text-transform: none;
  }
  & .checkbox-label {
    align-items: start;
  }
  & .main-content {
    @apply --container;
  }
  & .breadcrumb {
    margin-right: auto;
  }
  & .top-actions {
    display: flex;
    margin: var(--spacingMd) 0 var(--spacingSm) 0;

    @media (--desktop) {
      margin: var(--spacingMd) 0;
    }

    & .delete-widget {
      @apply --buttonTinyPrimary;

      margin: 0;
      margin-left: auto;
      width: auto;
    }
  }

  & .grid-one {
    display: grid;
    margin-bottom: var(--spacingMd);
    grid-gap: var(--spacingSm);
    align-items: top;

    & .checkbox {
      position: relative;
      top: 3rem;
    }

    & .example-button {
      @apply --buttonPrimary;
      display: block;
      width: 100%;
    }

    @media (--tablet) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (--desktop) {
      grid-gap: var(--spacingMd);
    }
  }

  & .grid-three {
    display: grid;
    margin-bottom: var(--spacingMd);
  }
}

& .image-upload {
  display: grid;
  grid-gap: var(--spacingMd);
  grid-template-columns: repeat(4, 1fr);
}

& .coverimage-form {
  display: grid;
  grid-gap: var(--spacingMd);
  /* grid-template-columns: 1fr; */
  @media (--tablet) {
    grid-template-columns: repeat(3, 1fr);
  }
}
& .select-image {
  @media (--tablet) {
    grid-column: 2 / 5;
  }
}
& .image {
  height: 100px;
}
& .bg-image {
  height: 320px;
  background-size: cover;
}
& .imagegrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: var(--spacingSm);
  grid-template-rows: min-content;
  @media (--tablet) {
    grid-template-columns: repeat(3, 1fr);
  }
  &.grid-four {
    @media (--tablet) {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
& .onecolumn {
  grid-template-columns: 1fr;
}
</style>
